import React from 'react';
import '../styles/AuthPage.css';


class AuthPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        };
    };

    componentDidMount = () => {

    }

    enterHandler = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            this.sendPhrase();
        }    
    }

    sendPhrase = async () =>{
        let input = document.getElementById("authPhraseInput")
        await this.props.logIn(input.value)
    };


    render(){
        return(
            <div id="AuthPage">
                <div id="ContentBox">
                    <h1>WatchStuffLive Closed Beta</h1>
                    <p>Please enter your beta access token</p>
                    <div id="Form">
                        <input id="authPhraseInput" type="text" placeholder="Type here..." autoFocus onKeyPress={this.enterHandler}></input>
                        <button onClick={this.sendPhrase}>Submit</button>
                    </div>
                </div>
            </div>
        )
    }
};

export default AuthPage;