import React from 'react';
import '../styles/Message.css';


class Message extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            userName: '',
            messageText: '',
        };
    };

    render(){       

        return(
        <div id="messageWindow">
            <p id="userName">{this.props.userName}</p>
            <p id="messageText">{this.props.messageText}</p>
        </div>
        )
    }
};

export default Message;