import React from 'react';
import '../styles/Stream.css';


class Stream extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        };
    };

    componentDidMount = () => {
    }


    sync = () => {
        let dateConvert = new Date(this.props.streamStartTime)
        let currentDate = new Date();
        let difference = (currentDate - dateConvert)/1000;
        let video = document.getElementById("video");
        video.src=this.props.currentStream + "&start=" + Math.round(difference);
    }

    render(){

        return(
            <div id="streamWindow">
                <button id="syncButton" onClick={this.sync}>Sync</button>
                <iframe
                    id='video'
                    src={this.props.currentStream} 
                    allow="autoplay"
                ></iframe>
            </div>
        )
    }
};

export default Stream;