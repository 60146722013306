import React from 'react';
import '../styles/Chat.css';

import Message from './Message.js';
import ChatBox from './ChatBox.js';

class Chat extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        };
    };
    
    componentDidUpdate = () => {  
        let chatWindow = document.getElementById("chatWindow")
        chatWindow.scrollTo(0, chatWindow.scrollHeight)
    }

    renderMessages = () => {
        return this.props.messageArray.map(message => (
            <Message messageText={message.messageText} userName={message.userName} key={message.id}/>
        ))
    }

    updateUsername = () =>{
        let input = document.getElementById("userNameInput")
        this.props.updateUsername(input.value)
    };

    render(){
        return(
            <div id="sidebar">
                <div id="tools">
                    <input id="userNameInput" type="text" placeholder="Change Username"></input>
                    <button onClick={this.updateUsername}>Change</button>
                </div>
                <div id="chatWindow">
                    {this.renderMessages()}
                </div>
                <ChatBox id="chatBox" sendMessage={this.props.sendMessage}/>
            </div>
        )
    }
};

export default Chat;