import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';

var config = {
    apiKey: "AIzaSyCVNgz7wegDE_ot-ai668e2jUKWkfL2ZoY",
    authDomain: "livetv-2020.firebaseapp.com",
    databaseURL: "https://livetv-2020.firebaseio.com",
    projectId: "livetv-2020",
    storageBucket: "livetv-2020.appspot.com",
    messagingSenderId: "541428551150",
    appId: "1:541428551150:web:49fc19cab07887757b12ff"
};
firebase.initializeApp(config);

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
