import React from 'react';
import '../styles/ChatBox.css'


class ChatBox extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            lastMessageSent : 0
        };
    };

    //allows the user to send messages by pressing the enter key
    enterHandler = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            this.detectSpam();
        }    
    }

    //makes sure the user is not spamming the chat by limiting how fast they can send messages (minimum 2 second delay between messages)
    detectSpam = () => {
        let currentTime = new Date();
        let timeDifference = currentTime - this.state.lastMessageSent;
        if (timeDifference >= 2000) {
            this.sendMessage()
            this.setState({lastMessageSent : new Date()})
        }
    }

    //sends the message to firebase, then clears and focuses the input so it's ready for another message
    sendMessage = async () =>{
        let input = document.getElementById("chatInput")
        if (input.value.length >= 1) {
            await this.props.sendMessage(input.value)
            input.value='';
            input.focus();
        }
    };
    
    render(){
        return(
            <div id="chatBoxWindow">
                <textarea id="chatInput" maxLength="100" placeholder="Type here to chat" autoFocus onKeyPress={this.enterHandler}/>
                <button id="sendButton" onClick={this.detectSpam}>Send</button>
            </div>
        )
    }
};

export default ChatBox;