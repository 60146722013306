import React from 'react';
import * as firebase from 'firebase';


import Authpage from './components/AuthPage.js'
import MainPage from './components/MainPage.js'

class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      userName: 'anon',
      authPhrase: '',
    };
  };

  componentDidMount = () => {
    let userRandNum = Math.floor((Math.random() * 100) + 1);
    let userName = "anon" + userRandNum.toString()
    this.setState({userName: userName})
  }

  //updates the authphrase stored in state
  //previously it compared the submitted phrase with the
  //phrase in the database, then changed the 'loggedIn' state to true
  //but if you knew how to change state with react tools
  //you could log in without knowing the phrase
  logIn = (phrase) => {
    this.setState({authPhrase: phrase})
  }


  //this function is for use during beta, as users are not currently able to create accounts
  updateUsername = (userName) => {
    this.setState({userName: userName});
  }
  
  render(){
    // let content;

    // const db = firebase.database();
    // const authPhraseRef = db.ref().child('authPhrase/');
    // authPhraseRef.on('value', snap => {
    //   this.authPhrase = snap.val()
    // })


    // if (this.authPhrase === this.state.authPhrase) {
    //   content = <MainPage 
    //   userName={this.state.userName} 
    //   updateUsername={this.updateUsername}
    //   ></MainPage>;
    // } else {
    //   content = <Authpage logIn={this.logIn}></Authpage>;
    // }

    return (
      <div className="App">
        <MainPage 
      userName={this.state.userName} 
      updateUsername={this.updateUsername}
      ></MainPage>;
      </div>
    )
  }
};

export default App;
