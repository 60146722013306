import React from 'react';
import * as firebase from 'firebase';

// import '../styles/Stream.css';

import Stream from './Stream.js';
import Chat from './Chat.js';

class MainPage extends React.Component{
  constructor(props){
      super(props);
      this.state = {
          currentStream: '',
          streamStartTime: '',
          chatHistory: '',
          messageArray: [],
      };
  };

  componentDidMount = () => {
    const db = firebase.database();
    const chatRef = db.ref().child('chatHistory');
    const streamRef = db.ref().child('streamInfo');
    chatRef.on('value', snap => {
      this.setState({ chatHistory: snap.val()})
    })
    streamRef.on('value', snap => {
      this.setState({currentStream: snap.val().currentStream, streamStartTime: snap.val().streamStartTime})
    })
    this.readMessages()
  }

  sendMessage = (messageText) =>{
    const db = firebase.database();
    const chatRef = db.ref().child('chatHistory/');
    chatRef.push({userName: this.props.userName, messageText: messageText})
    if (this.state.messageArray.length >= 25) {
      this.deleteOldMessage()
    }
  }
    
  deleteOldMessage = () => {
    const db = firebase.database();
    const chatRef = db.ref().child('chatHistory/');
    let test = this.state.messageArray[0].id;
    let msgRef = chatRef.child(test)
    msgRef.remove()
  }
    
  readMessages = () => {
    const db = firebase.database();
    const chatRef = db.ref().child('chatHistory');
    chatRef.on('value', snap => {
      let messageArray = [];
      snap.forEach(child => {
        const message = child.val()
        messageArray.push({ id: child.key, messageText: message.messageText, userName: message.userName})
      })
      this.setState({messageArray: messageArray})
    })
  }


  render(){
    return(
      <div id="MainPage">
        <Stream 
        currentStream={this.state.currentStream} 
        streamStartTime={this.state.streamStartTime}
        ></Stream>
        <Chat 
        updateUsername={this.props.updateUsername}
        messageArray={this.state.messageArray} 
        readMessages={this.readMessages} 
        sendMessage={this.sendMessage} 
        streamStartTime={this.state.streamStartTime}
        ></Chat>
      </div>
    )
  }
};

export default MainPage;